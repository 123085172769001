import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  async handle(request: HttpRequest<any>, next: HttpHandler) {
    let crData;
    if (!this.authService.userData) {
      await this.delay(1000);
    }
    await this.authService.userData.getIdToken().then(idToken => {
      crData = idToken;
    });

    if (crData) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${crData}`
        }
      });
    }

    // Important: Note the .toPromise()
    return next.handle(request).toPromise();
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
