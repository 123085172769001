<div class="app-footer">
	<div class="footer-bottom pt-3 d-flex flex-column flex-sm-row align-items-center">

		<span class="flex-grow-1"></span>
		<div class="d-flex align-items-center">
			<img class="logo" src="./assets/images/logo.png" alt="">
			<div>
				<p class="m-0">&copy; 2020 Fidelyo Pro</p>
				<p class="m-0">Tous droits réservés</p>
			</div>
		</div>
	</div>
</div>