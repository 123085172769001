import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShopService {
  constructor(private http: HttpClient) {}

  shopEndpoint = 'shops';

  getShops() {
    return this.http.get(environment.apiUrl + this.shopEndpoint);
  }

  getMyShops() {
    return this.http.get(environment.apiUrl + this.shopEndpoint + '/me');
  }

  getShopCategories() {
    return this.http.get(
      environment.apiUrl + this.shopEndpoint + '/categories'
    );
  }

  deleteShop(shopId: number) {
    return this.http.delete(
      environment.apiUrl + this.shopEndpoint + '/' + shopId
    );
  }

  getActiveShopsCount() {
    return this.http.get(
      environment.apiUrl + this.shopEndpoint + '/active/count'
    );
  }

  getEmployeesCount(shopId: number) {
    return this.http.get(
      environment.apiUrl + this.shopEndpoint + '/' + shopId + '/employee/count'
    );
  }

  getSMSCount(shopId: number) {
    return this.http.get(
      environment.apiUrl + this.shopEndpoint + '/' + shopId + '/sms/count'
    );
  }

  createShop(formData: any) {
    let object = {};
    formData.forEach((value, key) => {
      object[key] = value;
    });
    const json = JSON.stringify(object);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post(
      environment.apiUrl + this.shopEndpoint + '/activate',
      json,
      httpOptions
    );
  }
}
