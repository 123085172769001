import { ShopService } from 'src/app/shared/services/shop.service';
import { ShopModel } from './../models/shop.model';
import { Injectable, NgZone } from '@angular/core';
import { LocalStoreService } from './local-store.service';
import { Router } from '@angular/router';
import { of, BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData: User;
  selectedShop: ShopModel;
  userShops: ShopModel[];

  public eventAuthError = new BehaviorSubject<string>('');
  eventAuthError$ = this.eventAuthError.asObservable();

  public eventResetError = new BehaviorSubject<string>('');
  eventResetError$ = this.eventResetError.asObservable();

  public eventSwitchStore = new BehaviorSubject<string>('');
  eventSwitchStore$ = this.eventSwitchStore.asObservable();

  constructor(
    private store: LocalStoreService,
    private shopService: ShopService,
    private router: Router,
    public afAuth: AngularFireAuth,
    public ngZone: NgZone
  ) {
    // this.checkAuth();
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        this.store.setItem('user', JSON.stringify(this.userData));
        JSON.parse(this.store.getItem('user'));
      } else {
        this.store.setItem('user', null);
        JSON.parse(this.store.getItem('user'));
      }
    });

    if (!this.userShops || !this.selectedShop) {
      this.userShops = JSON.parse(this.store.getItem('shops'));
      if (this.userShops && this.userShops.length > 0) {
        this.selectedShop = this.userShops[0];
      }
    }
  }

  signin(email, password) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.getClaims();
        // this.SetUserData(result.user);
        this.userData = result.user;
        this.store.setItem('user', JSON.stringify(result.user));
      })
      .catch((error) => {
        this.eventAuthError.next(this.convertMessage(error.code));
        this.afAuth.auth.signOut().then(() => {
          this.store.clear();
        });
      });
  }

  // Returns true when user is looged
  get isLoggedIn(): boolean {
    const user = JSON.parse(this.store.getItem('user'));
    return user !== null ? true : false;
  }

  // Sign out
  signout() {
    return this.afAuth.auth.signOut().then(() => {
      this.store.clear();
      this.router.navigateByUrl('/sessions/signin');
    });
  }

  // reset password
  resetPassword(email: string) {
    return this.afAuth.auth
      .sendPasswordResetEmail(email)
      .then(() => {
        this.eventResetError.next(
          // tslint:disable-next-line:max-line-length
          "Un e-mail a été envoyé à l'adresse saisie si vous disposez d'un compte. Suivez les instructions fournies pour réinitialiser votre mot de passe."
        );
      })
      .catch((error) => {
        this.eventResetError.next(
          // tslint:disable-next-line:max-line-length
          "Un e-mail a été envoyé à l'adresse saisie si vous disposez d'un compte. Suivez les instructions fournies pour réinitialiser votre mot de passe."
        );
      });
  }

  // get custom claims
  getClaims() {
    return this.afAuth.auth.currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        if (!!idTokenResult.claims.retailer) {
          this.shopService.getMyShops().subscribe((res: any[]) => {
            this.userShops = res;
            this.selectedShop = res[0];
            this.store.setItem('shops', JSON.stringify(res));
            this.ngZone.run(() => {
              this.router.navigateByUrl('/dashboard');
            });
          });
        } else {
          this.eventAuthError.next(
            'Pour devenir partenaire, veuillez nous contacter via Whatsapp au +228 96 59 46 46.'
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /*getUserData(): User {
    if (!this.userData) {
      this.userData = JSON.parse(this.store.getItem('user')) as User;
    }
    return this.userData;
  }*/

  convertMessage(code: string): string {
    switch (code) {
      case 'auth/credential-already-in-use': {
        return 'Cet identifiant est déjà utilisé.';
      }
      case 'auth/email-already-in-use': {
        return 'Cette adresse email est déjà utilisée par un autre compte.';
      }
      case 'auth/expired-action-code': {
        return 'Le code a expiré.';
      }
      case 'auth/internal-error': {
        return 'Une erreur interne est survenue.';
      }
      case 'auth/invalid-auth-event': {
        return 'Une erreur interne est survenue.';
      }
      case 'auth/invalid-email': {
        return 'L\'adresse email n\'est pas valide.';
      }
      case 'auth/wrong-password': {
        return 'Le mot de passe est invalide ou cet utilisateur ne dispose pas de compte.';
      }
      case 'auth/user-not-found': {
        return 'Le mot de passe est invalide ou cet utilisateur ne dispose pas de compte.';
      }
      case 'auth/invalid-recipient-email': {
        return 'Le mot de passe est invalide ou cet utilisateur ne dispose pas de compte.';
      }
      case 'auth/network-request-failed': {
        return 'Une erreur réseau  s\'est produite.';
      }
      case 'auth/too-many-requests': {
        return 'Nous avons bloqué toutes les demandes de cet appareil en raison d\'une activité inhabituelle. Réessayez plus tard.';
      }
      case 'auth/user-disabled': {
        return 'Le compte utilisateur a été désactivé par un administrateur.';
      }
      case 'auth/web-storage-unsupported': {
        return 'Ce navigateur n\'est pas pris en charge ou les cookies et données tiers peuvent être désactivés.';
      }
      default: {
        return 'Erreur de connexion. Veuillez réessayer plus tard.';
      }
    }
  }
}
