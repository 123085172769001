<div class="not-found-wrap text-center">
  <h1 class="text-60">
    404
  </h1>
  <p class="text-36 subheading mb-3">Erreur!</p>
  <p class="mb-5 text-muted text-18">
    Désolé! La page que vous cherchez n'existe pas.
  </p>
  <button class="btn btn-lg btn-primary btn-rounded" (click)="goToHome()">
    Retournez à la page d'accueil
  </button>
</div>
