<div class="main-header">
    <div class="logo">
        <img src="./assets/images/logo.png" alt="">
    </div>

    <div class="menu-toggle" (click)="toggelSidebar()">
        <div></div>
        <div></div>
        <div></div>
    </div>

    <div class="d-flex align-items-center">
    </div>

    <div style="margin: auto"></div>

    <div class="header-part-right">

        <div ngbDropdown class="d-none d-md-block" *ngIf="shops?.length > 1">
            <button ngbDropdownToggle href="#"
                class="btn text-muted dropdown-toggle mr-3">{{selectedShop?.name}}</button>
            <div ngbDropdownMenu>
                <a ngbDropdownItem href="#" (click)="switchShop(shop)" *ngFor="let shop of shops">{{shop.name}}</a>
            </div>
        </div>

        <i fullScreenWindow class="i-Full-Screen header-icon d-none d-sm-inline-block" role="button"></i>


        <div ngbDropdown [placement]="'bottom-right'" class="user col align-self-end">
            <img src="./assets/images/account.png" id="userDropdown" ngbDropdownToggle alt="">

            <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">
                <div class="dropdown-header">
                    <i class="i-Lock-User mr-1"></i> {{userName}}
                </div>
                <!--<button class="dropdown-item">Account settings</button>
                <button class="dropdown-item">Billing history</button>-->
                <button class="dropdown-item" (click)="signout()">Se déconnecter</button>
            </div>
        </div>

    </div>

</div>