export const environment = {
  production: true,
  apiUrl: 'https://fidelyo-ws-prod-xyz.herokuapp.com/',
  apiKeyTiny: 's3gi94t1bix2qtg3hylxmijbkz8hbsuvs2szzu5yznn2djzd',
  paygateBaseUrl: '	https://paygateglobal.com/v1/page',
  paygateKey: 'd9bf2637-fa75-49c8-83c1-1cce0c778a10',
  paygateCallbackUrl: 'https://pro.fidelyo.co/campaigns/payment/process',
  firebase: {
    apiKey: 'AIzaSyAJAuW77RBnQt1jVYNNMSOs142E7kX1YM4',
    authDomain: 'fidelyo-prod-xyz.firebaseapp.com',
    databaseURL: 'https://fidelyo-prod-xyz.firebaseio.com',
    projectId: 'fidelyo-prod-xyz',
    storageBucket: 'fidelyo-prod-xyz.appspot.com',
    messagingSenderId: '564030076596',
    appId: '1:564030076596:web:9bb743dd6b3c7789b1c9e1',
    measurementId: 'G-ZF1JQBNCJC',
  },
};
