import { ClientsModule } from './views/clients/clients.module';
import { EmployeesModule } from './views/employees/employees.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/services/auth.guard';
import { BlankLayoutComponent } from './shared/components/layouts/blank-layout/blank-layout.component';
// tslint:disable-next-line:max-line-length
import { AdminLayoutSidebarLargeComponent } from './shared/components/layouts/admin-layout-sidebar-large/admin-layout-sidebar-large.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import {
  canActivate,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  hasCustomClaim,
} from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['/sessions/signin']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['dashboard']);
const retailerOnly = () => hasCustomClaim('retailer');

const loggedInRoutes: Routes = [
  {
    path: 'dashboard',
    ...canActivate(retailerOnly()),
    loadChildren: () =>
      import('./views/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'employees',
    loadChildren: () =>
      import('./views/employees/employees.module').then(
        (m) => m.EmployeesModule
      ),
  },
  {
    path: 'campaigns',
    loadChildren: () =>
      import('./views/campaigns/campaigns.module').then(
        (m) => m.CampaignsModule
      ),
  },

  {
    path: 'clients',
    loadChildren: () =>
      import('./views/clients/clients.module').then((m) => m.ClientsModule),
  },
  /*,

  {
    path: 'forms',
    loadChildren: () =>
      import('./views/forms/forms.module').then(m => m.AppFormsModule)
  },

  {
    path: 'tables',
    loadChildren: () =>
      import('./views/data-tables/data-tables.module').then(
        m => m.DataTablesModule
      )
  },
  {
    path: 'cards',
    loadChildren: () =>
      import('./views/cards/cards.module').then(m => m.CardsModule)
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./views/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'shops',
    loadChildren: () =>
      import('./views/shops/shops.module').then(m => m.ShopsModule)
  }*/
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    ...canActivate(redirectLoggedInToDashboard()),
    children: [
      {
        path: 'sessions',
        loadChildren: () =>
          import('./views/sessions/sessions.module').then(
            (m) => m.SessionsModule
          ),
      },
    ],
  },
  {
    path: '',
    component: AdminLayoutSidebarLargeComponent,
    ...canActivate(redirectUnauthorizedToLogin()),
    children: loggedInRoutes,
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
