import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { AuthService } from '../../../../services/auth.service';
import { ShopService } from 'src/app/shared/services/shop.service';
import { ShopModel } from 'src/app/shared/models/shop.model';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {
  notifications: any[];
  userName: any;
  shops: ShopModel[];
  selectedShop: ShopModel;

  constructor(
    private navService: NavigationService,
    private store: LocalStoreService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.showName();
    if (this.auth.userShops && this.auth.selectedShop) {
      this.shops = this.auth.userShops;
      this.selectedShop = this.auth.selectedShop;
    }
  }

  switchShop(shop) {
    console.log('SWITCH : ' + shop.name);
    this.selectedShop = shop;
    this.auth.selectedShop = shop;
    this.auth.eventSwitchStore.next('SWITCH : ' + shop.name);
  }

  async showName() {
    if (!this.auth.userData) {
      await this.delay(1000);
    }
    this.userName = this.auth.userData?.displayName;
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  toggelSidebar() {
    const state = this.navService.sidebarState;
    if (state.childnavOpen && state.sidenavOpen) {
      return (state.childnavOpen = false);
    }
    if (!state.childnavOpen && state.sidenavOpen) {
      return (state.sidenavOpen = false);
    }
    // item has child items
    if (
      !state.sidenavOpen &&
      !state.childnavOpen &&
      this.navService.selectedItem.type === 'dropDown'
    ) {
      state.sidenavOpen = true;
      setTimeout(() => {
        state.childnavOpen = true;
      }, 50);
    }
    // item has no child items
    if (!state.sidenavOpen && !state.childnavOpen) {
      state.sidenavOpen = true;
    }
  }

  signout() {
    this.auth.signout();
  }
}
